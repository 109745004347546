const SupportIcon = ({ width = 24, height = 24, color="black" , ...props}) => {
  return (
    <div {...props}>
      <svg width="154" height="174" viewBox="0 0 154 174" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_135_29)">
          <path
            d="M75.2895 173.159C72.2495 172.479 69.3295 171.529 66.9895 169.339C62.4795 165.129 60.7595 159.999 62.3895 154.039C64.0895 147.809 68.2895 143.999 74.6895 143.019C81.7495 141.929 87.7095 145.279 90.7595 151.789C91.1695 152.659 91.6295 152.899 92.5395 152.889C97.4395 152.849 102.35 152.919 107.25 152.859C114.18 152.769 120.27 148.259 121.73 142.059C118.72 142.949 115.8 142.849 112.9 141.929C106.7 139.969 102.45 134.369 102.4 127.509C102.29 113.759 102.21 100.009 102.42 86.2587C102.6 74.1187 115.12 67.1987 125.53 73.3687C130.36 76.2287 132.75 80.7687 132.77 86.3287C132.85 103.679 132.94 121.039 132.74 138.389C132.58 151.769 121.56 162.639 108.18 162.979C103 163.109 97.8095 162.979 92.6295 163.059C92.0495 163.059 91.1595 163.449 90.9395 163.909C88.6495 168.799 84.9295 171.819 79.6395 172.899C79.4295 172.939 79.2295 173.069 79.0195 173.159H75.2995H75.2895Z"
            fill={color} />
          <path
            d="M0.949453 75.5587C0.739453 35.7687 33.5095 0.0286865 76.9795 0.0286865C120.409 0.0286865 153.209 35.7187 153.009 76.0287C151.839 75.3387 150.809 74.5987 149.669 74.0887C147.779 73.2487 145.839 72.4587 143.869 71.8287C142.969 71.5387 142.629 71.1887 142.539 70.2387C141.659 60.5087 138.809 51.3887 133.859 42.9587C127.149 31.5287 117.859 22.8187 105.989 16.9387C94.8195 11.3987 82.9594 9.30869 70.5695 10.4887C59.8494 11.5087 49.9295 14.9987 40.9395 20.9487C29.1295 28.7687 20.6495 39.3287 15.4895 52.5287C13.2595 58.2187 11.9895 64.1487 11.3595 70.2287C11.3095 70.7287 10.9595 71.4587 10.5495 71.6287C7.37945 72.9987 4.15945 74.2487 0.939453 75.5487L0.949453 75.5587Z"
            fill={color} />
          <path
            d="M21.1795 106.699C21.1795 100.049 21.1495 93.3987 21.1795 86.7487C21.2195 79.8387 25.2695 73.9387 31.2295 71.9887C41.4595 68.6387 51.3795 75.3887 51.5495 86.1587C51.7595 99.9587 51.7895 113.769 51.5495 127.569C51.3395 139.629 38.8895 146.489 28.5195 140.399C23.4895 137.449 21.2295 132.729 21.1895 126.969C21.1395 120.209 21.1795 113.449 21.1795 106.679V106.699Z"
            fill={color} />
          <path
            d="M11.1895 131.469C5.60946 130.249 1.03946 124.129 0.909461 117.439C0.819461 112.989 0.889461 108.539 0.889461 104.089C0.889461 101.439 0.829461 98.7887 0.909461 96.1487C1.09946 89.8687 4.93946 84.4187 10.5795 82.3387C10.7795 82.2687 10.9995 82.2387 11.1895 82.1987V131.479V131.469Z"
            fill={color} />
          <path
            d="M142.77 131.499V82.1287C146.79 83.4887 149.84 86.0487 151.5 89.9087C152.41 92.0187 152.94 94.4487 153.01 96.7487C153.2 103.499 153.12 110.269 153.07 117.019C153.02 123.919 148.73 129.889 142.78 131.489L142.77 131.499Z"
            fill={color} />
        </g>
      </svg>
    </div>
  )
}

export default SupportIcon;