const LicenceIcon = ({ width = 24, height = 24, color="black" , ...props}) => {
  return (
    <div {...props}>
      <svg width="127" height="162" viewBox="0 0 127 162" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M14.4208 0.371094H40.3784C56.2934 0.371094 69.2201 13.3069 69.2201 29.2334V43.6645C69.2201 51.6277 75.6834 58.0958 83.6409 58.0958H98.0618C113.977 58.0958 126.903 71.0316 126.903 86.958V147.569C126.903 155.532 120.44 162 112.483 162H14.4208C6.46332 162 0 155.532 0 147.569V14.8022C0 6.83901 6.46332 0.371094 14.4208 0.371094ZM87.9151 137.902H79.7143C79.4015 137.902 79.1236 137.728 78.9498 137.45L63.7645 110.361C63.6255 110.083 63.3127 109.909 63 109.909H47.9537C47.4672 109.909 47.0849 109.526 47.0849 109.039V102.467C47.0849 101.98 47.4672 101.598 47.9537 101.598H63.278C73.0425 101.598 79.3668 95.9992 79.3668 87.4796C79.3668 78.96 73.0425 73.431 63.3822 73.431H47.9537C47.4672 73.431 47.0849 73.8136 47.0849 74.3004V86.6451C47.0849 87.1319 46.7027 87.5144 46.2162 87.5144H38.9189C38.4324 87.5144 38.0502 87.1319 38.0502 86.6451V65.9894C38.0502 65.5026 38.4324 65.12 38.9189 65.12H64.0077C78.7413 65.12 88.61 73.8483 88.61 87.4796C88.61 97.2859 82.7374 105.006 73.7374 107.857C73.2162 108.031 72.973 108.622 73.251 109.109L88.61 136.58C88.9228 137.171 88.5058 137.867 87.8456 137.867L87.9151 137.902Z"
          fill={color} />
        <path
          d="M80.7568 29.2333C80.7568 19.5314 77.2819 10.1425 70.9228 2.80518C97.1236 9.6904 117.591 30.1722 124.471 56.3917C117.139 50.0281 107.757 46.5508 98.0618 46.5508H83.6409C82.0425 46.5508 80.7568 45.2641 80.7568 43.6645V29.2333Z"
          fill={color} />
      </svg>
    </div>
  )
}

export default LicenceIcon;