// ConfirmDialog.js
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';
import TitleLayout from 'components/TitleLayout';
import ButtonReverto from 'components/Inputs/ButtonReverto';

const ConfirmDialog = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirm-dialog-container">
      <div className="confirm-dialog-content">
        <TitleLayout title={message} />
        <div className="confirm-dialog-actions">
          <ButtonReverto onClick={onConfirm}>Confirmer</ButtonReverto>
          <ButtonReverto onClick={onCancel} style={{ backgroundColor : "var(--text-secondary-color)"}} >Annuler</ButtonReverto>
        </div>
      </div>
    </div>
  );
};

ConfirmDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmDialog;