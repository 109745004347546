const ChevronRightIcon = ({ width = 24, height = 24 , ...props}) => {
  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox={"0 0 "+width+" "+height} strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
      </svg>  
    </div>
  )
}

export default ChevronRightIcon;