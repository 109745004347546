const ClientIcon = ({ width = 24, height = 24, color="black" , ...props}) => {
  return (
    <div {...props}>
      <svg width="139" height="177" viewBox="0 0 139 177" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M0.757749 156.935C1.34848 118.754 32.7616 88.2918 70.916 88.8829C108.237 89.4741 138.329 119.588 138.92 156.935C138.955 159.439 137.53 161.734 135.271 162.743C114.735 172.166 92.4257 177.035 69.8388 177C46.5222 177 24.3523 171.888 4.4064 162.743C2.14771 161.699 0.688251 159.404 0.757749 156.935Z"
          fill={color} />
        <path
          d="M65.0781 47.0847C60.804 42.3902 60.804 35.0182 65.0781 30.3585C69.3523 25.6988 76.7538 25.0033 81.7577 28.7936C82.5569 29.3848 83.7036 29.2804 84.3986 28.585L99.6187 13.354C100.453 12.5194 100.418 11.1633 99.5492 10.3635C81.8272 -5.4934 52.777 -1.80736 40.3021 21.4564C34.5337 32.2015 34.5337 45.2417 40.3021 56.0216C52.777 79.2853 81.7924 82.9714 99.5492 67.1145C100.418 66.3147 100.453 64.9585 99.6187 64.124L84.3986 48.893C83.7036 48.1975 82.5569 48.0584 81.7577 48.6844C76.7191 52.4747 69.4913 51.9531 65.0781 47.1195V47.0847Z"
          fill={color} />
      </svg>
    </div>
  )
}

export default ClientIcon;