import React from 'react';

// global_module
const Home = React.lazy(() => import('features/misc/pages/Home'));
const Contact = React.lazy(() => import('features/misc/pages/Contact'));
const Page404 = React.lazy(() => import('features/misc/pages/Page404'));

// reverto_module
const Catalog = React.lazy(() => import('features/licence/pages/Catalog'));
const Contents = React.lazy(() => import('features/production/pages/Contents'));
const Content = React.lazy(() => import('features/production/pages/Content'));
const Module = React.lazy(() => import('features/production/pages/Module'));
const Version = React.lazy(() => import('features/production/pages/Version'));
const Missions = React.lazy(() => import('features/mission/pages/Missions'));
const Mission = React.lazy(() => import('features/mission/pages/Mission'));
const Tokens = React.lazy(() => import('features/token/pages/Tokens'));
const Report = React.lazy(() => import('features/report/pages/Report'));

const Parameter = React.lazy(() => import('features/misc/pages/Parameter'));
const Ressources = React.lazy(() => import('features/misc/pages/Ressources'));
// const Library = React.lazy(() => import('features/misc/pages/Library'));

// user_module
const Login = React.lazy(() => import('features/auth/pages/Login'));
const Logout = React.lazy(() => import('features/auth/pages/Logout'));
// const Users = React.lazy(() => import('features/user/pages/Users'));
const Profil = React.lazy(() => import('features/user/pages/Profil'));
const Enterprise = React.lazy(() => import('features/enterprise/pages/Enterprise'));
const Permissions = React.lazy(() => import('features/auth/pages/Roles'));

// hardware_module
// const Hardwares = React.lazy(() => import('features/hardware/pages/Hardwares'));
// const Pilotage = React.lazy(() => import('features/pilotage/pages/Pilotage'));


const routes = [
// #########################
//           VISITOR
// #########################
  { path: '/', name: 'Accueil', component: Login, requiredLogin : false },
  { path: '/login', name: 'Connexion', component: Login, requiredLogin : false },
  { path: '/logout', name: 'Déconnexion', component: Logout, requiredLogin : true },
  
  // #########################
  //           INTERNE
  // #########################
  { path: '/dashboard', name: 'Dashboard', component: Home, requiredLogin : true },
  { path: '/contents', name: 'Contenus', component: Contents, requiredLogin : true },
  { path: '/content/:id', name: 'Contenu', component: Content, requiredLogin : true },
  { path: '/module/:id', name: 'Module', component: Module, requiredLogin : true },
  { path: '/module/:id/:tab', name: 'Module', component: Module, requiredLogin : true },
  { path: '/version/:id', name: 'Version', component: Version, requiredLogin : true },
  
  { path: '/tokens', name: 'Tokens', component: Tokens, requiredLogin : true },
  { path: '/parameters/:tab?', name: 'Parametres', component: Parameter, requiredLogin : true },
  { path: '/rapport/:uuid', name: 'Rapport', component: Report, requiredLogin : true },
  
  // { path: '/library', name: 'Library', component: Library, requiredLogin : true },
  // { path: '/users', name: 'Utilisateurs', component: Users, requiredLogin : true },
  
  { path: '/enterprise/:tab?', name: 'Entreprise', component: Enterprise, requiredLogin : true },
  { path: '/enterprise/:tab/:id', name: 'Entreprise', component: Enterprise, requiredLogin : true },
  { path: '/enterprise/:tab/:id/:subid', name: 'Entreprise', component: Enterprise, requiredLogin : true },
  { path: '/enterprise/:tab/:id/:subid/:subsubid', name: 'Entreprise', component: Enterprise, requiredLogin : true },
  { path: '/permissions', name: 'Permissions', component: Permissions, requiredLogin : true },
  
  { path: '/missions', name: 'Missions', component: Missions, requiredLogin : true },
  { path: '/mission/:id', name: 'Mission', component: Mission, requiredLogin : true },

  // { path: '/hardwares', name: 'Hardware', component: Hardwares, requiredLogin : true },
  
  // #########################
  //           CLIENT
  // #########################
  { path: '/catalogue', name: 'Catalog', component: Catalog, requiredLogin : true },
  { path: '/profil', name: 'Profil', component: Profil, requiredLogin : true },
  { path: '/ressources', name: 'Ressources', component: Ressources, requiredLogin : true },
  { path: '/contact', name: 'Contact', component: Contact, requiredLogin : true },

  { path: '/*', name: 'Erreur', component: Page404, requiredLogin : false },
];

export default routes;
