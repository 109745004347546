const ProductionIcon = ({ width = 24, height = 24, color="black" , ...props}) => {
  return (
    <div {...props}>
      <svg width="193" height="172" viewBox="0 0 193 172" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_36_43)">
          <path fillRule="evenodd" clipRule="evenodd"
            d="M177.43 0.210022H94.0398V172H177.43C185.9 172 192.77 165.13 192.77 156.66V15.55C192.77 7.08002 185.91 0.210022 177.43 0.210022ZM125.28 129.05C121.89 129.05 119.14 126.3 119.14 122.91V49.29C119.14 45.9 121.89 43.15 125.28 43.15C128.67 43.15 131.42 45.9 131.42 49.29V122.91C131.42 126.3 128.67 129.05 125.28 129.05ZM132.67 156.66C132.67 158.35 131.3 159.73 129.6 159.73H117.33C115.64 159.73 114.26 158.36 114.26 156.66V144.39C114.26 142.7 115.63 141.32 117.33 141.32H129.6C131.29 141.32 132.67 142.69 132.67 144.39V156.66ZM132.67 27.82C132.67 29.51 131.3 30.89 129.6 30.89H117.33C115.64 30.89 114.26 29.52 114.26 27.82V15.55C114.26 13.86 115.63 12.48 117.33 12.48H129.6C131.29 12.48 132.67 13.85 132.67 15.55V27.82ZM172.55 156.66C172.55 158.35 171.18 159.73 169.48 159.73H157.21C155.52 159.73 154.14 158.36 154.14 156.66V144.39C154.14 142.7 155.51 141.32 157.21 141.32H169.48C171.17 141.32 172.55 142.69 172.55 144.39V156.66ZM172.55 27.82C172.55 29.51 171.18 30.89 169.48 30.89H157.21C155.52 30.89 154.14 29.52 154.14 27.82V15.55C154.14 13.86 155.51 12.48 157.21 12.48H169.48C171.17 12.48 172.55 13.85 172.55 15.55V27.82Z"
            fill={color} />
          <path fillRule="evenodd" clipRule="evenodd"
            d="M81.2098 119.06C63.0098 119.06 48.2498 104.3 48.2498 86.1C48.2498 67.9 63.0098 53.14 81.2098 53.14V0.410034H73.0998C65.0398 0.410034 58.1698 6.24003 56.8398 14.18L55.2798 23.6C55.0998 24.65 54.2698 25.89 52.6698 26.66C49.6598 28.11 46.7598 29.78 43.9998 31.67C42.5398 32.68 41.0598 32.78 40.0398 32.4L31.0998 29.04C23.5498 26.21 15.0698 29.26 11.0398 36.24L2.93984 50.28C-1.09016 57.26 0.519839 66.12 6.73984 71.24L14.1198 77.32C14.9498 78.01 15.6098 79.33 15.4698 81.1C15.2198 84.43 15.2198 87.78 15.4698 91.11C15.5998 92.87 14.9498 94.2 14.1298 94.89L6.73984 100.97C0.519839 106.09 -1.08016 114.96 2.93984 121.93L11.0398 135.97C15.0698 142.95 23.5498 145.99 31.0998 143.16L40.0598 139.8C41.0698 139.42 42.5498 139.53 44.0198 140.52C46.7598 142.4 49.6498 144.08 52.6798 145.53C54.2798 146.3 55.1098 147.53 55.2898 148.61L56.8498 158.02C58.1798 165.97 65.0498 171.79 73.1098 171.79H81.2098V119.05V119.06Z"
            fill={color} />
        </g>
        <defs>
          <clipPath id="clip0_36_43">
            <rect width="192.04" height="171.79" fill="white" transform="translate(0.729736 0.210022)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default ProductionIcon;