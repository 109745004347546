// ConfirmContext.js
import { Dialog } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { createContext, useContext, useState } from 'react';

interface ConfirmContextType {
  confirm: (message: string, onConfirm: () => void, onCancel: () => void) => void;
}

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

export const useConfirm = () => {
  return useContext<ConfirmContextType>(ConfirmContext);
};

export const ConfirmProvider = ({ children }) => {
  const [confirmState, setConfirmState] = useState({
    isOpen: false,
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const confirm = (message: string, onConfirm: () => void, onCancel: () => void) => {
    setConfirmState({ isOpen: true, message, onConfirm, onCancel });
  };

  const handleConfirm = () => {
    if (confirmState.onConfirm) {
      confirmState.onConfirm();
    }
    setConfirmState({ isOpen: false, message: '', onConfirm: null, onCancel: null });
  };

  const handleCancel = () => {
    if (confirmState.onCancel) {
      confirmState.onCancel();
    }
    setConfirmState({ isOpen: false, message: '', onConfirm: null, onCancel: null });
  };

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <Dialog open={confirmState.isOpen} onClose={handleCancel}>
        <ConfirmDialog message={confirmState.message} onConfirm={handleConfirm} onCancel={handleCancel} />
      </Dialog>
    </ConfirmContext.Provider>
  );
};