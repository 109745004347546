import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';


import AuthProvider from 'features/auth/store/auth.provider';
import InterfaceProvider from './features/misc/store/interface.provider';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <InterfaceProvider>
            <App />
            <ReactQueryDevtools initialIsOpen />
        </InterfaceProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);