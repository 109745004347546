const TokenIcon = ({ color="black"}) => {
  return (
    <svg width="173" height="172" viewBox="0 0 173 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_135_45)">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M86.8553 0.210022C39.4153 0.210022 0.965332 38.66 0.965332 86.1C0.965332 133.54 39.4153 171.99 86.8553 171.99C134.295 171.99 172.745 133.54 172.745 86.1C172.745 38.66 134.295 0.210022 86.8553 0.210022ZM119.265 132.23H108.775C108.375 132.23 108.005 132.01 107.805 131.66L88.3653 97.02C88.1653 96.67 87.7953 96.45 87.3953 96.45H68.1453C67.5353 96.45 67.0353 95.95 67.0353 95.34V86.92C67.0353 86.31 67.5353 85.81 68.1453 85.81H87.7753C100.275 85.81 108.385 78.63 108.385 67.72C108.385 56.81 100.275 49.77 87.9053 49.77H68.1353C67.5253 49.77 67.0253 50.27 67.0253 50.88V66.68C67.0253 67.29 66.5253 67.79 65.9153 67.79H56.5653C55.9553 67.79 55.4553 67.29 55.4553 66.68V40.25C55.4553 39.64 55.9553 39.14 56.5653 39.14H88.6953C107.575 39.14 120.215 50.31 120.215 67.73C120.215 80.29 112.675 90.14 101.175 93.81C100.505 94.02 100.205 94.8 100.545 95.41L120.215 130.58C120.625 131.32 120.095 132.23 119.245 132.23H119.265Z"
          fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_135_45">
          <rect width="171.79" height="171.79" fill="white" transform="translate(0.965332 0.210022)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TokenIcon;