import { ConfirmProvider } from "hooks/useConfirm";
import React, { createContext } from "react";

export const InterfaceContext = createContext({
    data : null,
});

const InterfaceProvider = ({ children }) => {
    const [data, setData] = React.useState(null);

    return (
        <InterfaceContext.Provider value={ data }>
            <ConfirmProvider>
                { children }
            </ConfirmProvider>
        </InterfaceContext.Provider>
    );
}

export default InterfaceProvider;
