const ChevronLeftIcon = ({ width = 24, height = 24, ...props}) => {
  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox={"0 0 "+width+" "+height} strokeWidth="1.5" stroke="currentColor" >
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
      </svg>
    </div>
  )
}

export default ChevronLeftIcon;