import { Link, useLocation } from 'react-router-dom';

import { Tooltip } from '@mui/material';
import { AuthContext } from 'features/auth/store/auth.provider';
import { useContext } from 'react';
import './style.css';

const RNavItem = ({ open, icon, text, link, ...props}) => {
    const location = useLocation();
    const { user } = useContext(AuthContext);
    link = link.includes('enterprise') ? link + '/' + user.enterprise._id : link;
    return (
        <Link className="RNavItemLink" to={link} {...props}>
            {
                open ? 
                    <div id="RNavItem" className={(location.pathname.includes(link) ? "active" : "")} >
                        <i>{icon}</i>
                        <p>{text}</p>
                    </div>
                : 
                    <Tooltip title={text} placement="right" arrow>
                        <div id="RNavItem" className={(location.pathname.includes(link) ? "close active" : "close")} >
                            <i>{icon}</i>
                        </div>
                    </Tooltip>
            }
        </Link>
    )
}

export default RNavItem;