const EnterpriseIcon = ({ width = 24, height = 24, color="black" , ...props}) => {
  return (
    <div {...props}>
      <svg width="163" height="177" viewBox="0 0 163 177" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M13.5801 0C9.83008 0 6.79004 3.04001 6.79004 6.79001C6.79004 10.54 9.83008 13.58 13.5801 13.58V162.88H6.79004C3.04004 162.88 0 165.92 0 169.67C0 173.42 3.04004 176.46 6.79004 176.46H156.09C159.84 176.46 162.88 173.42 162.88 169.67C162.88 165.92 159.84 162.88 156.09 162.88H149.3V13.58C153.05 13.58 156.09 10.54 156.09 6.79001C156.09 3.04001 153.05 0 149.3 0H13.5701H13.5801ZM54.3 33.93C50.55 33.93 47.51 36.97 47.51 40.72C47.51 44.47 50.55 47.51 54.3 47.51H67.8701C71.6201 47.51 74.66 44.47 74.66 40.72C74.66 36.97 71.6201 33.93 67.8701 33.93H54.3ZM47.51 67.86C47.51 64.11 50.55 61.07 54.3 61.07H67.8701C71.6201 61.07 74.66 64.11 74.66 67.86C74.66 71.61 71.6201 74.65 67.8701 74.65H54.3C50.55 74.65 47.51 71.61 47.51 67.86ZM54.3 88.22C50.55 88.22 47.51 91.26 47.51 95.01C47.51 98.76 50.55 101.8 54.3 101.8H67.8701C71.6201 101.8 74.66 98.76 74.66 95.01C74.66 91.26 71.6201 88.22 67.8701 88.22H54.3ZM88.2301 40.72C88.2301 36.97 91.27 33.93 95.02 33.93H108.59C112.34 33.93 115.38 36.97 115.38 40.72C115.38 44.47 112.34 47.51 108.59 47.51H95.02C91.27 47.51 88.2301 44.47 88.2301 40.72ZM95.02 61.08C91.27 61.08 88.2301 64.12 88.2301 67.87C88.2301 71.62 91.27 74.66 95.02 74.66H108.59C112.34 74.66 115.38 71.62 115.38 67.87C115.38 64.12 112.34 61.08 108.59 61.08H95.02ZM88.2301 95.01C88.2301 91.26 91.27 88.22 95.02 88.22H108.59C112.34 88.22 115.38 91.26 115.38 95.01C115.38 98.76 112.34 101.8 108.59 101.8H95.02C91.27 101.8 88.2301 98.76 88.2301 95.01ZM54.3 156.09V135.73C54.3 131.98 57.3401 128.94 61.0901 128.94H101.81C105.56 128.94 108.6 131.98 108.6 135.73V156.09C108.6 159.84 105.56 162.88 101.81 162.88H61.0901C57.3401 162.88 54.3 159.84 54.3 156.09Z"
          fill={color} />
      </svg> 
    </div>
  )
}

export default EnterpriseIcon;