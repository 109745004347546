const RevertoLogoIcon = ({ width = "222", height = "318", color = "#FFFFFF", ...props}) => {
  return (
    <div {...props}>
      <svg width={width} height={height} viewBox="0 0 222 318" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_15_256)">
          <path fill={color} d="M153.97 192.12C152.8 190.03 153.83 187.4 156.11 186.67C195.39 174.14 221.13 140.52 221.13 97.63C221.13 38.14 177.99 0 113.52 0H3.8C1.7 0 0 1.7 0 3.8V94.06C0 96.16 1.7 97.86 3.8 97.86H35.71C37.81 97.86 39.51 96.16 39.51 94.06V40.12C39.51 38.02 41.21 36.32 43.31 36.32H110.8C153.03 36.32 180.73 60.39 180.73 97.62C180.73 134.85 153.03 159.37 110.35 159.37H43.3C41.2 159.37 39.5 161.07 39.5 163.17V191.91C39.5 194.01 41.2 195.71 43.3 195.71H109.03C110.4 195.71 111.67 196.45 112.34 197.65L178.73 315.92C179.4 317.12 180.67 317.86 182.04 317.86H217.85C220.75 317.86 222.58 314.74 221.16 312.21L153.98 192.13L153.97 192.12Z"/>
        </g>
      </svg>
    </div>
  )
}

export default RevertoLogoIcon;