const MaterielIcon = ({ width = 24, height = 24, color="black" , ...props}) => {
  return (
    <div {...props}>
      <svg width="232" height="167" viewBox="0 0 232 167" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_135_35)">
          <path
            d="M88.9495 158.819C88.7195 158.179 88.2495 157.659 87.6395 157.349C79.3695 153.089 73.8695 144.599 73.6695 134.989C73.5595 129.769 73.5595 124.959 73.6695 120.269C73.6995 119.109 73.7995 117.979 73.9695 116.869C74.2295 115.179 72.9595 113.639 71.2495 113.639H35.1595V17.9787H183.25V59.4587C183.25 60.3787 183.72 61.2387 184.48 61.7587C188.04 64.1887 190.9 67.5587 192.71 71.5087C193.15 72.4787 194.14 73.0887 195.2 73.0887H196.35C196.9 73.0887 197.44 73.0987 197.99 73.1287C199.55 73.1987 200.86 71.9387 200.86 70.3687C200.86 53.1187 200.86 35.8687 200.85 18.6187C200.84 7.77867 193.46 0.398672 182.64 0.388672C133.7 0.388672 84.7595 0.388672 35.8095 0.398672C34.5095 0.398672 33.1895 0.418672 31.9195 0.668672C23.2595 2.33867 17.5895 9.30867 17.5795 18.3587C17.5595 51.6487 17.5795 84.9287 17.5295 118.219C17.5295 119.609 17.1795 121.119 16.5695 122.369C11.6295 132.399 6.57953 142.379 1.61953 152.399C0.949526 153.749 0.339526 155.239 0.179526 156.709C-0.380474 162.079 3.70953 166.159 9.55953 166.159C35.6795 166.159 61.7995 166.159 87.9195 166.159C89.9895 166.159 91.2995 163.989 90.3795 162.129C89.8395 161.049 89.3695 159.939 88.9595 158.809L88.9495 158.819Z"
            fill={color} />
          <path
            d="M231.54 120.349C231.43 115.569 228.24 111.319 223.66 109.939C221.97 109.429 220.11 109.499 218.34 109.249C218.3 97.1487 208.48 87.3387 196.36 87.3387H180.55C180.57 87.0987 180.59 86.8487 180.59 86.5987V81.2987C180.59 76.1087 176.39 71.9087 171.2 71.9087H148.28C143.09 71.9087 138.89 76.1087 138.89 81.2987V86.5987C138.89 86.8487 138.91 87.0887 138.93 87.3387H123.11C110.98 87.3387 101.14 97.1687 101.13 109.299C100.98 109.299 100.83 109.299 100.67 109.269C100.26 109.209 99.8395 109.259 99.4195 109.269C93.1095 109.399 88.0795 114.289 87.9395 120.599C87.8295 125.299 87.8395 129.999 87.9395 134.699C88.0495 140.089 91.6795 144.479 96.9495 145.689C98.2495 145.989 99.6195 145.999 101.13 146.159C101.13 146.199 101.13 146.239 101.14 146.279V147.359H101.22C101.2 158.109 109.8 166.149 120.08 166.129L143.59 166.169C143.66 166.169 143.72 166.169 143.79 166.169H144.07C147.48 166.089 150.13 164.959 152.43 162.219C154.63 159.599 157.22 157.309 159.73 154.789C162.97 158.039 166.08 161.119 169.15 164.239C170.31 165.409 171.62 166.099 173.3 166.089C182.33 166.069 191.37 166.169 200.4 166.039C208.7 165.919 215.78 160.129 217.68 152.039C218.13 150.129 218.22 148.139 218.5 146.009C219.03 146.009 219.59 146.029 220.15 146.009C226.54 145.789 231.42 140.999 231.55 134.629C231.65 129.879 231.65 125.119 231.53 120.379L231.54 120.349ZM178.9 100.249C178.9 102.049 177.44 103.499 175.65 103.499H143.83C142.03 103.499 140.58 102.039 140.58 100.249V99.5587C140.58 97.7587 142.04 96.3087 143.83 96.3087H175.65C177.45 96.3087 178.9 97.7687 178.9 99.5587V100.249Z"
            fill={color} />
        </g>
      </svg> 
    </div>
  )
}

export default MaterielIcon;