const ParameterIcon = ({ width = 24, height = 24, color="black" , ...props}) => {
  return (
    <div {...props}>
      <svg width="167" height="178" viewBox="0 0 167 178" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M75.0866 0.71875C66.7866 0.71875 59.7165 6.71874 58.3465 14.8987L56.7366 24.5988C56.5566 25.6888 55.6966 26.9487 54.0466 27.7487C50.9466 29.2387 47.9666 30.9588 45.1266 32.9088C43.6266 33.9488 42.1066 34.0488 41.0566 33.6588L31.8566 30.1987C24.0866 27.2887 15.3566 30.4187 11.2066 37.6087L2.86656 52.0587C-1.28344 59.2387 0.376597 68.3687 6.7766 73.6387L14.3766 79.8987C15.2366 80.6087 15.9166 81.9687 15.7666 83.7887C15.5066 87.2187 15.5066 90.6688 15.7666 94.0988C15.9066 95.9088 15.2366 97.2887 14.3866 97.9887L6.7766 104.249C0.376597 109.519 -1.28344 118.649 2.86656 125.829L11.2066 140.279C15.3566 147.459 24.0866 150.589 31.8566 147.679L41.0766 144.219C42.1166 143.829 43.6366 143.939 45.1466 144.959C47.9666 146.899 50.9466 148.619 54.0566 150.119C55.7066 150.919 56.5666 152.179 56.7466 153.289L58.3566 162.979C59.7266 171.159 66.7965 177.159 75.0965 177.159H91.7865C100.077 177.159 107.157 171.159 108.527 162.979L110.137 153.279C110.317 152.189 111.167 150.929 112.827 150.119C115.937 148.629 118.917 146.899 121.737 144.959C123.247 143.929 124.767 143.829 125.807 144.219L135.037 147.679C142.797 150.579 151.527 147.449 155.667 140.269L164.017 125.819C168.167 118.639 166.507 109.509 160.107 104.239L152.507 97.9787C151.647 97.2687 150.967 95.9087 151.117 94.0887C151.377 90.6587 151.377 87.2087 151.117 83.7787C150.977 81.9688 151.647 80.5887 152.497 79.8887L160.097 73.6288C166.507 68.3588 168.157 59.2387 164.017 52.0487L155.677 37.5988C151.527 30.4188 142.797 27.2887 135.027 30.1987L125.797 33.6588C124.767 34.0488 123.247 33.9388 121.737 32.9088C118.897 30.9688 115.917 29.2387 112.827 27.7487C111.167 26.9587 110.317 25.6988 110.137 24.5988L108.517 14.8987C107.157 6.71874 100.077 0.71875 91.7766 0.71875H75.0866ZM83.4266 122.869C102.167 122.869 117.357 107.679 117.357 88.9388C117.357 70.1988 102.167 55.0087 83.4266 55.0087C64.6866 55.0087 49.4966 70.1988 49.4966 88.9388C49.4966 107.679 64.6866 122.869 83.4266 122.869Z"
          fill={color} />
      </svg>
    </div>
  )
}

export default ParameterIcon;